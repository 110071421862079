










































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import SListing from '@stratumfive/ui-baseplate/src/components/SListing/SListing.vue';

import ReportAvatar from '@/components/shared/ReportAvatar.vue';
import ReportStatusSummary from '@/components/report/ReportStatusSummary.vue';

@Component({
  components: {
    ReportAvatar,
    ReportStatusSummary,
    SListing,
  },
})
export default class ReportSummaryCard extends Vue {
  @Prop({ required: true })
  private report: any;

  private expanded = false;

  private newReport() {
    this.$router.push({
      name: 'report',
      params: { id: `${this.report.id}`, version: this.report.version },
    }).catch((e) => {});
  }
}
