

























































import Vue from 'vue';
import SLayoutDefault from '@stratumfive/ui-baseplate/src/components/SLayoutDefault/SLayoutDefault.vue';
import ReportSummaryCard from '@/components/report/ReportSummaryCard.vue';
import { FormSchema } from '../../rxdb/modules/form-schemas.module';
import { Form } from '../../shared/types';

export default Vue.extend({
  name: 'ReportsContent',
  metaInfo: {
    title: 'Reports',
  },
  components: {
    SLayoutDefault,
    ReportSummaryCard,
  },
  computed: {
    formSchemas(): FormSchema[] {
      return this.$store.state.formSchemas.formSchemas.filter((formSchema) => formSchema.published);
    },
    activeForms(): Form[] {
      const formSchemaIds = this.formSchemas.map((formSchema) => formSchema.id);
      return this.$store.state.forms.latestForms.filter((form) => formSchemaIds.includes(form.formSchemaId));
    },
    loading(): boolean {
      return this.$store.state.forms.loading || this.$store.state.formSchemas.loading;
    },
  },
  methods: {
    refreshReports() {
      this.$store.dispatch('forms/loadForms');
      this.$store.dispatch('formSchemas/syncFormSchemas');
    },
  },
});
