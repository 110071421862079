




import { Component, Vue } from 'vue-property-decorator';
import ReportsContent from '@/components/reports/ReportsContent.vue';

@Component({
  components: {
    ReportsContent,
  },
  metaInfo: {
    title: 'Reports',
  },
})
export default class Reports extends Vue {}
