var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-badge',{attrs:{"left":"","color":"primary","overlap":""}},[_c('span',{attrs:{"slot":"badge"},slot:"badge"},[_vm._v(_vm._s(_vm.getAllSubmissions(_vm.report).length))]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-avatar',_vm._g({staticClass:"mr-4 mb-2",attrs:{"color":"grey darken-4","size":"36"}},on),[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v(" library_books ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.getAllSubmissions(_vm.report).length)+" Total submissions")])])],1),_c('v-badge',{attrs:{"left":"","color":"warning","overlap":""}},[_c('span',{attrs:{"slot":"badge"},slot:"badge"},[_vm._v(_vm._s(_vm.getPendingSubmissions(_vm.report).length))]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-avatar',_vm._g({staticClass:"mr-4 mb-2",attrs:{"color":"grey darken-4","size":"36"}},on),[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v(" query_builder ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.getPendingSubmissions(_vm.report).length)+" Pending submissions")])])],1),_c('v-badge',{attrs:{"left":"","color":"success","overlap":""}},[_c('span',{attrs:{"slot":"badge"},slot:"badge"},[_vm._v(_vm._s(_vm.getCompleteSubmissions(_vm.report).length))]),_c('v-tooltip',{attrs:{"bottom":"","color":"parimary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-avatar',_vm._g({staticClass:"mr-4 mb-2",attrs:{"color":"grey darken-4","size":"36"}},on),[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v(" done ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.getCompleteSubmissions(_vm.report).length)+" Completed")])])],1),_c('v-badge',{attrs:{"left":"","color":"purple","overlap":""}},[_c('span',{attrs:{"slot":"badge"},slot:"badge"},[_vm._v(_vm._s(_vm.getDraftSubmissions(_vm.report).length))]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-avatar',_vm._g({staticClass:"mr-4 mb-2",attrs:{"color":"grey darken-4","size":"36"}},on),[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v(" save ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.getDraftSubmissions(_vm.report).length)+" Drafts")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }