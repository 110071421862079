
























































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component
export default class ReportStatusSummary extends Vue {
  @Getter('findSubmissions') private findSubmissions;

  @Prop({ required: true })
  private report: any;

  private getAllSubmissions(report: any) {
    return this.findSubmissions(
      (submission) => submission.reportId === report.id,
    );
  }

  private getPendingSubmissions(report: any) {
    return this.findSubmissions(
      (submission) => submission.reportId === report.id && submission.status === 'pending',
    );
  }

  private getAmmendSubmissions(report: any) {
    return this.findSubmissions(
      (submission) => submission.reportId === report.id && submission.status === 'ammend',
    );
  }

  private getCompleteSubmissions(report: any) {
    return this.findSubmissions(
      (submission) => submission.reportId === report.id && submission.status === 'complete',
    );
  }

  private getDraftSubmissions(report: any) {
    return this.findSubmissions(
      (submission) => submission.reportId === report.id && submission.status === 'draft',
    );
  }
}
