














import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ReportAvatar extends Vue {
  @Prop() private colour: string;

  @Prop() private type: string;

  @Prop() private size: number;

  @Prop() private iconSize: string;

  private get setIcon() {
    let selectedIcon = 'assignment';
    switch (this.type) {
      case 'noon':
        selectedIcon = 'my_location';
        break;
      case 'departure':
        selectedIcon = 'near_me';
        break;
      case 'arrival':
        selectedIcon = 'place';
        break;
      case 'change_of_speed':
        selectedIcon = 'toys';
        break;
      case 'fuel_change_over':
        selectedIcon = 'local_gas_station';
        break;
      case 'delay':
        selectedIcon = 'query_builder';
        break;
      case 'cargo':
        selectedIcon = 'receipt';
        break;
      default:
        selectedIcon = 'assignment';
        break;
    }
    return selectedIcon;
  }

  private get setColour() {
    let selectedColour = 'primary';
    switch (this.colour) {
      case 'orange':
        selectedColour = 'accent';
        break;
      case 'green':
        selectedColour = 'success';
        break;
      case 'purple':
        selectedColour = 'purple';
        break;
      case 'blue':
        selectedColour = 'blue';
        break;
      case 'red':
        selectedColour = 'error';
        break;
      case 'pink':
        selectedColour = 'pink';
        break;
      case 'yellow':
        selectedColour = 'yellow';
        break;
      case 'primary':
        selectedColour = 'primary';
        break;
      default:
        selectedColour = 'primary';
        break;
    }
    return selectedColour;
  }

  private get setIconSize() {
    return this.iconSize === 'small';
  }

  private get setSize() {
    return this.size ? this.size : 46;
  }
}
